import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { Button, Modal } from "antd";
import { connectors } from "../Account/config";
import Text from "antd/lib/typography/Text";
import "./login.css";

const styles = {
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "12px",
    backgroundColor: "rgb(244, 244, 244)",
    cursor: "pointer",
  },
  text: {
    color: "#21BF96",
  },
  connector: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "20px 5px",
    cursor: "pointer",
  },
  icon: {
    alignSelf: "center",
    fill: "rgb(40, 13, 95)",
    flexShrink: "0",
    marginBottom: "8px",
    height: "30px",
  },
  loginModal: {
    width: "500px",
    height: "275px",
    margin: "0 auto",
    backgroundColor: "white",
    borderRadius: "20px",
  },
};

function Login() {
  const { isInitialized } = useMoralis();
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);
  const { authenticate } = useMoralis();

  useEffect(() => {
    if (isInitialized) {
      console.log("initialized!!");
    }
  }, [isInitialized]);

  return (
    <div style={styles.loginModal}>
      <h1
        style={{
          fontSize: "36px",
          fontWeight: "600",
          paddingTop: "40px",
          paddingLeft: "80px",
        }}
      >
        Login Here
      </h1>
      <p style={{ padding: "0 80px" }}>
        Connect your wallet to verify that you are holding at least one
        Metaversity NFT
      </p>
      <Button
        type="primary"
        htmlType="submit"
        className="loginButton"
        onClick={() => setIsAuthModalVisible(true)}
      >
        Authenticate
      </Button>
      <Modal
        visible={isAuthModalVisible}
        footer={null}
        onCancel={() => setIsAuthModalVisible(false)}
        bodyStyle={{
          padding: "15px",
          fontSize: "17px",
          fontWeight: "500",
        }}
        style={{ fontSize: "16px", fontWeight: "500" }}
        width="340px"
      >
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            fontWeight: "700",
            fontSize: "20px",
          }}
        >
          Connect Wallet
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          {connectors.map(({ title, icon, connectorId }, key) => (
            <div
              style={styles.connector}
              key={key}
              onClick={async () => {
                try {
                  await authenticate({ provider: connectorId });
                  window.localStorage.setItem("connectorId", connectorId);
                  setIsAuthModalVisible(false);
                  window.location = "/courses";
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              <img src={icon} alt={title} style={styles.icon} />
              <Text style={{ fontSize: "14px" }}>{title}</Text>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
}

export default Login;
