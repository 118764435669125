import React, { useEffect, useState } from "react";
import { useMoralis, useMoralisFile } from "react-moralis";
import { Form, Input, Button } from "antd";
import "./profile.css";
import defaultProfilePic from "../../img/default-user-icon.png";

function Profile() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [discord, setDiscord] = useState("");
  const [biography, setBiography] = useState("");
  const [location, setLocation] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [infoSaved, setInfoSaved] = useState("");
  const [fileTarget, setFileTarget] = useState("");
  const [owls, setOwls] = useState([]);
  const { saveFile } = useMoralisFile();
  const { isInitialized, Moralis } = useMoralis();
  const { TextArea } = Input;

  // get basic profile info
  const getProfileInformation = async () => {
    Moralis.User.currentAsync().then(function (user) {
      setFullName(user.get("fullName"));
      setEmail(user.get("email"));
      setDiscord(user.get("discord"));
      setLocation(user.get("location"));
      setBiography(user.get("biography"));
      setOwls(user.get("owlsMetadata"));
      if (user.get("profilePhoto")) {
        setPhotoUrl(user.get("profilePhoto").url());
      } else {
        setPhotoUrl(defaultProfilePic);
      }
    });
  };

  // upload profile photo
  const uploadFile = () => {
    saveFile("photo.jpg", fileTarget, {
      type: "image/png",
      onSuccess: (profilePhoto) => {
        profilePhoto.save().then(
          function (res) {
            // The file has been saved to Moralis.
            console.log("res: ", res);
            let currentUser = Moralis.User.current();
            currentUser.set("profilePhoto", profilePhoto);
            currentUser.save().then(
              (user) => {
                // Execute any logic that should take place after the object is saved.
                setPhotoUrl(user.get("profilePhoto").url());
                window.location.reload();
              },
              (error) => {
                // Execute any logic that should take place if the save fails
                alert(
                  "Failed to save object, with error code: " + error.message,
                );
              },
            );
          },
          function (error) {
            // The file either could not be read, or could not be saved to Moralis.
            console.log("error: ", error);
          },
        );
      },
      onError: (error) => console.log(error),
    });
  };
  const fileInput = (e) => setFileTarget(e.target.files[0]);

  // save user's basic profile info
  const saveProfileInformation = (userObj) => {
    let currentUser = Moralis.User.current();
    currentUser.set("fullName", userObj.fullName);
    currentUser.set("email", userObj.email);
    currentUser.set("discord", userObj.discord);
    currentUser.set("location", userObj.location);
    currentUser.set("biography", userObj.biography);
    setInfoSaved(true);
    currentUser.save().then(
      (user) => {
        // Execute any logic that should take place after the object is saved.
        console.log("user saved with objectId: " + JSON.stringify(user));

        setTimeout(() => {
          setInfoSaved(false);
        }, 2000);
      },
      (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Moralis.Error with an error code and message.
        alert("Failed to save object, with error code: " + error.message);
      },
    );
  };

  const ProfileForm = () => {
    const onFinish = (values) => {
      saveProfileInformation(values);
    };

    return (
      <Form
        name="basic"
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 8 }}
        initialValues={{
          fullName: fullName,
          email: email,
          discord: discord,
          location: location,
          biography: biography,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Full Name"
          name="fullName"
          rules={[{ required: true, message: "Please input your full name" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Email" name="email" rules={[{ required: false }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Discord ID"
          name="discord"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="About You"
          name="biography"
          rules={[{ required: false }]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 2, span: 8 }}>
          <Button type="primary" htmlType="submit" className="profileSubmit">
            {infoSaved ? "Info Saved" : "Save Information"}
          </Button>
        </Form.Item>
      </Form>
    );
  };

  useEffect(() => {
    if (isInitialized) {
      getProfileInformation();
    }
  }, [
    fullName,
    email,
    biography,
    location,
    photoUrl,
    isInitialized,
    infoSaved,
  ]);

  return (
    <div style={{ padding: "15px", maxWidth: "1030px", width: "100%" }}>
      <h1>Profile Information</h1>
      <br />
      <ProfileForm />
      <br />
      <h1>Your Owls</h1>
      {owls.map(function (nft) {
        return (
          <div className="owlGallery">
            <img src={nft.image} width="100" alt={nft.metadata.name} />
            <label>{nft.metadata.name}</label>
          </div>
        );
      })}
      <br />
      <h1>Profile Picture</h1>
      <br />
      <div>
        <img style={{ height: "100px" }} src={photoUrl} />
        <br />
        <input type="file" onChange={fileInput} />
        <br />
        <br />
        <button
          className="profileSubmit"
          onClick={uploadFile}
          style={{
            padding: "5px 10px 5px 10px",
            display: fileTarget ? "block" : "none",
          }}
        >
          Save Photo
        </button>
        <br />
        <br />
      </div>
    </div>
  );
}

export default Profile;
