import React, { useEffect, useState } from "react";
import { Button, Alert } from "antd";
import "./courses.css";
import courseOneHeader from "../../img/nft-flipping-cover.png";
import courseTwoHeader from "../../img/crypto-staking-lending.png";
import { useMoralis } from "react-moralis";

function CoursesDirectory() {
  const { isInitialized, Moralis } = useMoralis();
  const [courseProgress, setCourseProgress] = useState(null);
  const [email, setEmail] = useState("");
  const [discord, setDiscord] = useState("");
  const [numMetaversityNFTs, setNumMetaversityNFTs] = useState(0);
  const [payments, setPayments] = useState([]);

  // get basic profile info
  const getProfileInformation = async () => {
    let userQuery = new Moralis.Query(Moralis.User);
    userQuery.equalTo("objectId", Moralis.User.current()["id"]);
    const results = await userQuery.find({ useMasterKey: true });
    if (results.length > 0) {
      let user = results[0];
      // console.log("user course: ", user.get("courseProgress"));
      setCourseProgress(user.get("courseProgress"));
      setEmail(user.get("email"));
      setDiscord(user.get("discord"));
      setNumMetaversityNFTs(user.get("nftCount"));
      if (user.get("payment_link")) {
        setPayments(user.get("payment_link"));
      }
    }
  };

  // updates progress bars on each course
  const updateCourseProgressBars = async () => {
    const courseOne = document.getElementById("nft-flipping");
    const courseTwo = document.getElementById("staking-lending-crypto");

    // course one
    if (courseProgress && courseProgress["nft-flipping"]) {
      const percentage = `${courseProgress["nft-flipping"]["percent_complete"]}%`;
      courseOne.style.width = percentage;
    }
    // course two
    if (courseProgress && courseProgress["staking-lending-crypto"]) {
      const percentageTwo = `${courseProgress["staking-lending-crypto"]["percent_complete"]}%`;
      courseTwo.style.width = percentageTwo;
    }
  };

  // shows purchase modal
  function buyCourse(course) {
    if (email) {
      if (course == "nft-flipping") {
        window.location.href = "https://buy.stripe.com/6oEdRo3VBeji9eU144";
      } else if (course == "staking-lending-crypto") {
        window.location.href = "https://buy.stripe.com/dR67t08bRdfe62I6op";
      }
    } else {
      window.alert(
        "You must save your email address first by visiting the 'Profile' page above before purchasing this course.",
      );
    }
  }

  useEffect(() => {
    if (isInitialized && !courseProgress) {
      getProfileInformation();
    }
    if (courseProgress) {
      updateCourseProgressBars();
    }
  }, [isInitialized, courseProgress]);

  return (
    <div style={{ padding: "15px", maxWidth: "1120px", width: "100%" }}>
      <div className="courseBanner"></div>

      {!email || !discord ? (
        <a href="/profile">
          <Alert
            className="bannerMessage"
            message="📣 Stay Updated"
            description="Want the latest latest news and courses by metaversity? Add your discord ID (required) or email (optional) on your profile page by clicking here."
            type="warning"
          />
        </a>
      ) : null}

      {/* courses */}
      <div className="coursesFlexGrid">
        {/* course one */}
        <div className="col">
          <img src={courseOneHeader} />
          <br />
          <h2>NFT Flipping</h2>
          The amount of information out there about how to make money buying and
          selling NFTs is overwhelming. This course will help you make sense of
          this emerging market with tips and best practices from Daniel100Eth, a
          successful NFT trader who has grown a $4k initial investment into a
          7-figure portfolio in under a year.
          <br />
          <br />
          <strong>Course Length:</strong>
          <br />1 – 1.5 Hours
          <br />
          <br />
          <strong>Partner:</strong>
          <br />
          Daniel100ETH
          <br />
          <br />
          <strong>
            {courseProgress && courseProgress["nft-flipping"]
              ? courseProgress["nft-flipping"]["percent_complete"]
              : "0"}
            % Completed
          </strong>
          <div className="w3-border">
            <div
              className="w3-gold"
              id="nft-flipping"
              style={{ height: "16px" }}
            ></div>
          </div>
          <br />
          <br />
          {numMetaversityNFTs > 0 ||
          payments.includes("plink_1KxEDpLxSpLhOqFMAsuXsWQB") ? (
            <a href="/course/1">
              <Button
                type="primary"
                htmlType="submit"
                className="buttonCourseStart"
              >
                Go to Course
              </Button>
            </a>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              className="buttonCourseStart"
              onClick={() => buyCourse("nft-flipping")}
            >
              Buy Course
            </Button>
          )}
        </div>

        {/* course two */}
        <div className="col">
          <img src={courseTwoHeader} />
          <br />
          <h2>Staking & Lending Crypto</h2>
          Staking & Lending Crypto is one of the most reliable mechanisms to
          earn passive income in crypto. This course helps explain the nuances
          of staking and lending, what that means, and how that plays into
          passive income streams in the broader Web3 space.
          <br />
          <br />
          <strong>Course Length:</strong>
          <br />
          1.5 – 2 Hours
          <br />
          <br />
          <strong>Partner:</strong>
          <br />
          Joe & Brian (long-time crypto investors)
          <br />
          <br />
          <strong>
            {courseProgress && courseProgress["staking-lending-crypto"]
              ? courseProgress["staking-lending-crypto"]["percent_complete"]
              : "0"}
            % Completed
          </strong>
          <div className="w3-border">
            <div
              className="w3-gold"
              id="staking-lending-crypto"
              style={{ height: "16px" }}
            ></div>
          </div>
          <br />
          <br />
          {numMetaversityNFTs > 0 ||
          payments.includes("plink_1LZm4gLxSpLhOqFMz0ZXyr5s") ? (
            <a href="/course/2">
              <Button
                type="primary"
                htmlType="submit"
                className="buttonCourseStart"
              >
                Go to Course
              </Button>
            </a>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              className="buttonCourseStart"
              onClick={() => buyCourse("staking-lending-crypto")}
            >
              Buy Course
            </Button>
          )}
        </div>

        {/* course three */}
        <div className="col">
          Portfolio strategies for high-risk investments <br />
          <strong>Coming Soon...</strong>
        </div>
      </div>
    </div>
  );
}

export default CoursesDirectory;
