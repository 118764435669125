import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import "./privateroute.css";
import { useNFTBalances } from "react-moralis";
import { useVerifyMetadata } from "hooks/useVerifyMetadata";
import { useMoralis } from "react-moralis";

function PrivateRoute({
  children,
  isAuthenticated,
  isWeb3Enabled,
  needsPaymentOrOwls,
  ...rest
}) {
  const { data: NFTBalances } = useNFTBalances();
  const [numMetaversityNFTs, setNumMetaversityNFTs] = useState(0);
  const [stripePaid, setStripePaid] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { verifyMetadata } = useVerifyMetadata();
  const { Moralis } = useMoralis();

  // checks if in the club
  const isPartOfMetaversity = async () => {
    if (NFTBalances) {
      // find MV NFTs
      let metaversityBalance = 0;
      let metaversityOwls = [];
      NFTBalances.result.map((nft) => {
        // verify Metadata
        nft = verifyMetadata(nft);
        if (nft.token_address == "0x4515aade4455de6fab65999efc4552e656f9d2f5") {
          metaversityBalance++;
          metaversityOwls.push(nft);
        }
      });

      // save MV owls in Users table
      setNumMetaversityNFTs(metaversityBalance);
      updateUserWithNFTCount(metaversityBalance, metaversityOwls); // update num MV NFTs

      // find if user paid via stripe
      let userQuery = new Moralis.Query(Moralis.User);
      userQuery.equalTo("objectId", Moralis.User.current()["id"]);
      const results = await userQuery.find({ useMasterKey: true });
      if (results.length > 0) {
        let user = results[0];
        let customerId = user.get("stripe_customer_id");
        if (customerId) {
          setStripePaid(true);
        }
      }

      // done loading data
      setDataLoaded(true);
    }
  };

  // updates num NFTs being held
  const updateUserWithNFTCount = (numOwls, owlsMetadata) => {
    const currentUser = Moralis.User.current();
    currentUser.set("nftCount", numOwls);
    currentUser.set("owlsMetadata", owlsMetadata);
    currentUser.save().then(
      (user) => {
        // Execute any logic that should take place after the object is saved.
        console.log("user saved with objectId: " + user.id);
      },
      (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Moralis.Error with an error code and message.
        console.log("Failed to save object, with error code: " + error.message);
      },
    );
  };

  useEffect(() => {
    isPartOfMetaversity();
  }, [NFTBalances, numMetaversityNFTs]);

  if (!isWeb3Enabled) {
    return <div className="loader"></div>;
  } else if (
    !needsPaymentOrOwls ||
    ((numMetaversityNFTs > 0 || stripePaid) && dataLoaded)
  ) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            <div
              className="notInTheClub"
              style={{ display: dataLoaded ? "block" : "none" }}
            >
              You are not a part of Metaversity. <br /> Please buy a{" "}
              <a
                target="_blank"
                rel="noreferrer"
                style={{ color: "blue" }}
                href="https://opensea.io/collection/metaversity-college-of-web3"
              >
                Metaversity NFT
              </a>{" "}
              to access or{" "}
              <a
                target="_blank"
                rel="noreferrer"
                style={{ color: "blue" }}
                href="https://buy.stripe.com/6oEdRo3VBeji9eU144"
              >
                buy NFT Flipping
              </a>{" "}
              or{" "}
              <a
                target="_blank"
                rel="noreferrer"
                style={{ color: "blue" }}
                href="https://buy.stripe.com/6oEdRo3VBeji9eU144"
              >
                buy Staking and Lending.
              </a>
            </div>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
}

export default PrivateRoute;
