import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useParams } from "react-router-dom";
import "./courses.css";
// import "scorm-again";

function Course() {
  const params = useParams();
  const [courseContent, setCourseContent] = useState("");
  const { isInitialized, Moralis } = useMoralis();

  // get basic profile info
  const getProfileInformation = async () => {
    let userQuery = new Moralis.Query(Moralis.User);
    userQuery.equalTo("objectId", Moralis.User.current()["id"]);
    const results = await userQuery.find({ useMasterKey: true });
    if (results.length > 0) {
      // user results
      let user = results[0];
      console.log("user course: ", user.get("courseProgress"));

      // set values used later to update LMS via moralis cloud code
      window.current_user_id = user.id;

      // get course one progress and save to global value for SCORM
      if (
        user.get("courseProgress") &&
        "nft-flipping" in user.get("courseProgress")
      ) {
        window.current_user_progress_course_one =
          user.get("courseProgress")["nft-flipping"]["cmi"];
      }

      // get course two progress and save to global value for SCORM
      if (
        user.get("courseProgress") &&
        "staking-lending-crypto" in user.get("courseProgress")
      ) {
        window.current_user_progress_course_two =
          user.get("courseProgress")["staking-lending-crypto"]["cmi"];
      }

      // set course content
      if (params.id == "1") {
        setCourseContent(`/flipping-nfts-latest/scormdriver/indexAPI.html`);
      } else if (params.id == "2") {
        setCourseContent(`/staking-lending-crypto/scormdriver/indexAPI.html`);
      } else if (params.id == "3") {
        setCourseContent("/flipping-nfts-latest/scormdriver/indexAPI.html");
      }
    }
  };

  useEffect(() => {
    if (isInitialized) {
      getProfileInformation();
    }
  }, [isInitialized]);

  return (
    <div
      style={{
        padding: "0px",
        marginTop: "-40px",
        maxWidth: "100%",
        width: "100%",
      }}
    >
      {/* course */}
      <div className="coursesFlexGrid" style={{ padding: 0 }}>
        {/* Rise Courses */}
        <iframe
          style={{ width: "100%", minHeight: "100vh" }}
          src={courseContent}
        ></iframe>
      </div>
    </div>
  );
}

export default Course;
