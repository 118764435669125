import { useState, useEffect } from "react";
import { useMoralis } from "react-moralis";
import "./profilePhoto.css";

const styles = {
  token: {
    padding: "0 7px",
    height: "42px",
    gap: "5px",
    maxWidth: "150px",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  nameLabel: {
    marginTop: "-12px",
    marginLeft: "45px",
  },
};

function ProfilePhoto() {
  // profile vars
  const { Moralis, isAuthenticated } = useMoralis();
  const [fullName, setFullName] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const noPhotoUrl = "https://etherscan.io/images/main/empty-token.png";

  // get basic profile info
  const getProfileInformation = async () => {
    if (isAuthenticated) {
      const currentUser = Moralis.User.current();
      setFullName(currentUser.get("fullName"));
      if (currentUser.get("profilePhoto")) {
        setPhotoUrl(currentUser.get("profilePhoto").url());
      }
    }
  };

  useEffect(() => {
    getProfileInformation();
  }, [fullName, photoUrl, isAuthenticated]);

  return (
    <div style={styles.token} id="profileInfoWrapper">
      <img
        src={photoUrl || noPhotoUrl}
        alt="logo"
        style={{
          height: "35px",
          width: "35px",
          borderRadius: "50%",
          float: "left",
        }}
      />
      <p style={styles.nameLabel}>{fullName}</p>
    </div>
  );
}

export default ProfilePhoto;
